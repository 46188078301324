import { useSnackbar } from 'notistack';
import { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { noop } from 'utils';
import {
  createCompanyProfileApi,
  editUsersApi,
  getUserApi,
  loginUserApi,
  registerUserApi,
  updateCompanyProfileApi,
  updateUserProfileApi,
} from 'utils/constants/apis';
import { DEFAULT_ERROR_MESSAGE } from 'utils/constants/app';
import { LS_USER_COMPANY_ID, LS_USER_ID } from 'utils/constants/localStorage';
import axiosInstance from 'utils/helpers/axios';

const UserContext = createContext({
  loading: false,
  auth: null,
  userDetails: null,
  companyDetails: null,
  handleLogin: noop,
  registerUser: noop,
  clearSession: noop,
  createCompanyProfile: noop,
  updateUserProfile: noop,
  updateCompanyProfile: noop,
  getUserDetails: noop,
});

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const defaultUserId = localStorage.getItem(LS_USER_ID) || null;
  const defaultCompanyId = localStorage.getItem(LS_USER_COMPANY_ID) || null;
  const defaultAuth = {
    userId: defaultUserId,
    companyId: defaultCompanyId,
  };

  const { t } = useTranslation('app');
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [userDetails, setUserDetails] = useState(null);
  const [auth, setAuth] = useState(defaultAuth);
  const [companyDetails, setCompanyDetails] = useState(null);

  const handleLogin = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    axiosInstance
      .post(loginUserApi(), payload)
      .then(data => {
        enqueueSnackbar(t('Login successful'), {
          variant: 'success',
        });

        const { id, company } = data;
        localStorage.setItem(LS_USER_ID, id);
        localStorage.setItem(LS_USER_COMPANY_ID, company?.id);
        const auth = {
          userId: id,
          companyId: company?.id,
        };
        setAuth(auth);
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });
        localStorage.removeItem(LS_USER_ID);
        localStorage.removeItem(LS_USER_COMPANY_ID);
        onError(err);
      })
      .finally(() => setLoading(false));
  };

  const registerUser = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    axiosInstance
      .post(registerUserApi(), payload)
      .then(data => {
        enqueueSnackbar(t('Registration successful'), {
          variant: 'success',
        });

        const { id } = data;
        localStorage.setItem(LS_USER_ID, id);
        setAuth({ userId: id, companyId: null });
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });
        localStorage.removeItem(LS_USER_ID);
        onError(err);
      })
      .finally(() => setLoading(false));
  };

  const updateUserProfile = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    console.log({ auth });
    const { userId } = auth;

    axiosInstance
      .put(editUsersApi(userId), payload)
      .then(({ data }) => {
        setUserDetails(data);
        onSuccess(data);
      })
      .catch(err => {
        onError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createCompanyProfile = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    setCompanyDetails(payload);
    axiosInstance
      .post(createCompanyProfileApi(), payload)
      .then(data => {
        const { id } = data;
        localStorage.setItem(LS_USER_COMPANY_ID, id);
        setAuth(o => ({ ...o, companyId: id }));
        setCompanyDetails(data);
        onSuccess(data);
      })
      .catch(err => {
        onError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateCompanyProfile = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    setCompanyDetails(payload);
    const { companyId } = auth;
    axiosInstance
      .post(updateCompanyProfileApi(companyId), payload)
      .then(({ data }) => {
        setCompanyDetails(data);
        onSuccess(data);
      })
      .catch(err => {
        onError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getUserDetails = (id, onSuccess = noop, onError = noop) => {
    setLoading(true);
    axiosInstance
      .get(getUserApi(id))
      .then(data => {
        enqueueSnackbar(t('Login successful'), {
          variant: 'success',
        });

        setUserDetails(data);
        setCompanyDetails(data?.company);
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });
        localStorage.removeItem(LS_USER_ID);
        localStorage.removeItem(LS_USER_COMPANY_ID);
        onError(err);
      })
      .finally(() => setLoading(false));
  };

  const clearSession = (onSuccess = noop) => {
    localStorage.removeItem(LS_USER_ID);
    localStorage.removeItem(LS_USER_COMPANY_ID);
    localStorage.clear();
    setAuth(null);
    onSuccess();
  };

  const contextValue = {
    loading,
    auth,
    userDetails,
    companyDetails,
    handleLogin,
    registerUser,
    clearSession,
    updateUserProfile,
    createCompanyProfile,
    updateCompanyProfile,
    getUserDetails,
  };

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export default UserProvider;

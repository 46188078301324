import moment from 'moment';
import { useSnackbar } from 'notistack';
import qs from 'query-string';
import { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { noop } from 'utils';
import {
  downloadSubscriptionInvoiceApi,
  getSubscriptionActivePlanApi,
  getSubscriptionPlansApi,
} from 'utils/constants/apis';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_PAGINATION,
  DEFAULT_PAGINATION_COUNT,
  DEFAULT_PAGINATION_PAGE_NO,
} from 'utils/constants/app';
import axiosInstance from 'utils/helpers/axios';

const SubscriptionContext = createContext({
  loading: false,
  activePlan: null,
  subscriptionPlans: [],
  subscriptionHistory: DEFAULT_PAGINATION,
  getActiveSubscriptionPlan: noop,
  getSubscriptionPlans: noop,
  getSubscriptionHistory: noop,
  renewSubscriptionPlan: noop,
  downloadInvoice: noop,
});

export const useSubscription = () => useContext(SubscriptionContext);

export const SubscriptionProvider = ({ children }) => {
  const { t } = useTranslation('pages');
  const [loading, setLoading] = useState(false);
  const [activePlan, setActivePlan] = useState(null);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [subscriptionHistory, setSubscriptionHistory] =
    useState(DEFAULT_PAGINATION);
  const { enqueueSnackbar } = useSnackbar();

  const getActiveSubscriptionPlan = (onSuccess = noop, onError = noop) => {
    setLoading(true);
    const params = qs.stringify({
      count: DEFAULT_PAGINATION_COUNT,
      page: DEFAULT_PAGINATION_PAGE_NO,
    });
    axiosInstance
      .get(getSubscriptionActivePlanApi(params))
      .then(({ data }) => {
        if (data?.length) {
          setActivePlan(data[0]);
        }

        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });
        onError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSubscriptionPlans = (onSuccess = noop, onError = noop) => {
    setLoading(true);
    const params = qs.stringify({
      count: DEFAULT_PAGINATION_COUNT,
      page: DEFAULT_PAGINATION_PAGE_NO,
    });

    axiosInstance
      .get(getSubscriptionPlansApi(params))
      .then(({ data = [] }) => {
        setSubscriptionPlans(data);
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });
        onError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSubscriptionHistory = (onSuccess = noop, onError = noop) => {
    setLoading(true);
    axiosInstance
      .get(getSubscriptionPlansApi())
      .then(data => {
        const { page } = data;
        setSubscriptionHistory(o => ({
          ...o,
          ...data,
          data: page > 0 ? [...o.data, ...data.data] : data.data,
        }));

        onSuccess(data);
      })
      .catch(err => {
        // enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
        //   variant: 'error',
        // });
        // onError(err);
      })
      .finally(() => {
        const page = 0;
        // dummy
        const data = [
          {
            subscriptionplanid: 1,
            subscriptionPlanType: 'Premium',
            planDuration: 1,
            freeDuration: 1,
            createdDate: new Date(),
            startDate: new Date(),
            endDate: new Date(),
            amount: 1000,
            paymentMethod: 'Credit Card',
            currency: '￥',
          },
          {
            subscriptionplanid: 1,
            subscriptionPlanType: 'Advance',
            planDuration: 1,
            freeDuration: 3,
            createdDate: new Date(),
            startDate: new Date(),
            endDate: new Date(),
            amount: 1000,
            paymentMethod: 'Debit Card',
            currency: '￥',
          },
          {
            subscriptionplanid: 1,
            subscriptionPlanType: 'Pro',
            planDuration: 1,
            freeDuration: 2,
            createdDate: new Date(),
            startDate: new Date(),
            endDate: new Date(),
            amount: 1000,
            paymentMethod: 'Credit Card',
            currency: '￥',
          },
        ];

        setSubscriptionHistory(o => ({
          ...o,
          ...data,
          data: page > 0 ? [...o.data, ...data] : data,
        }));

        setLoading(false);
      });
  };

  const renewSubscriptionPlan = (onSuccess = noop, onError = noop) => {
    setLoading(true);
    axiosInstance
      .get(getSubscriptionPlansApi())
      .then(data => {
        onSuccess(data);
      })
      .catch(err => {
        // enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
        //   variant: 'error',
        // });
        // onError(err);
      })
      .finally(() => {
        // dummy
        enqueueSnackbar(t('Payment Successful'), {
          variant: 'success',
        });
        setLoading(false);
      });
  };

  const downloadInvoice = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    axiosInstance
      .post(downloadSubscriptionInvoiceApi(), payload)
      .then(data => {
        onSuccess(data);
      })
      .catch(err => {
        // enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
        //   variant: 'error',
        // });
        // onError(err);
      })
      .finally(() => {
        // dummy
        enqueueSnackbar(t('Invoice Downloaded'), {
          variant: 'success',
        });
        setLoading(false);
      });
  };

  const contextValue = {
    loading,
    activePlan,
    subscriptionPlans,
    subscriptionHistory,
    getActiveSubscriptionPlan,
    getSubscriptionPlans,
    getSubscriptionHistory,
    renewSubscriptionPlan,
    downloadInvoice,
  };

  return (
    <SubscriptionContext.Provider value={contextValue}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionProvider;

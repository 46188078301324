import common from './common.json';
import sidebar from './sidebar.json';
import login from './login.json';
import admin from './admin.json';
import app from './app.json';

const en = {
  app,
  admin,
  common,
  login,
  sidebar,
};
export default en;

import {
  SIGNOUT,
  PROFILE,
  SETTINGS,
  ABOUT,
  HELP_AND_FEEDBACK,
  COMPANY_PROFILE,
  DASHBOARD,
  MANAGE_STUDENTS,
  REPORTS,
  jobsCreateJobsPage,
  jobsSearchResourcePage,
  jobsAppliedJobsPage,
  jobsInterviewPage,
  jobsOfferPage,
  relationshipMyVendorsPage,
  relationshipSearchVendorsPage,
  recruitmentQuestionnairesManagePage,
} from './routes';
import {
  BusinessCenterOutlined,
  DashboardRounded,
  Diversity3Rounded,
  Groups2Outlined,
  InfoRounded,
  LogoutOutlined,
  SettingsRounded,
} from '@mui/icons-material';
import {
  itMapConsultantIcon,
  itMapMidCareerIcon,
  itMapStudentIcon,
} from './images';

export const APP_BAR_HEIGHT = 50;
export const APP_LIST_VIEW = 'list';
export const APP_TILE_VIEW = 'tile';
export const APP_DEFAULT_VIEW = APP_LIST_VIEW;
export const APP_SIDEBAR_WIDTH = 230;
export const DRAWER_WIDTH = 230;
export const DRAWER_WIDTH_MINI = 90;
export const DEFAULT_PAGINATION_PAGE_NO = 0;
export const DEFAULT_PAGINATION_COUNT = 10;
export const DEFAULT_PAGINATION = {
  data: [],
  page: DEFAULT_PAGINATION_PAGE_NO,
  count: DEFAULT_PAGINATION_COUNT,
  total: 0,
  next: false,
  previous: false,
};
export const DEFAULT_SORTING = 'ASC';
export const DEFAULT_ERROR_MESSAGE =
  'Something went wrong. Please refresh and try again.';
export const DEFAULT_DELETING_MESSAGE = 'Deleting...';
export const DEFAULT_DELETED_MESSAGE = 'Successfully deleted';

export const DRAWER_MENU = [
  {
    id: 1,
    title: 'Dashboard',
    url: DASHBOARD,
    icon: DashboardRounded,
  },
  {
    id: 2,
    title: 'Manage Jobs',
    icon: BusinessCenterOutlined,
    items: [
      {
        id: 1,
        title: 'Create Jobs',
        url: jobsCreateJobsPage(),
      },
      {
        id: 1,
        title: 'Search Resource',
        url: jobsSearchResourcePage(),
      },
      {
        id: 1,
        title: 'Applied Jobs',
        url: jobsAppliedJobsPage(),
      },
      {
        id: 1,
        title: 'Manage Interview',
        url: jobsInterviewPage(),
      },
      {
        id: 1,
        title: 'Job Offers',
        url: jobsOfferPage(),
      },
    ],
  },
  {
    id: 2,
    title: 'Relationship',
    icon: BusinessCenterOutlined,
    items: [
      {
        id: 1,
        title: 'My Vendors',
        url: relationshipMyVendorsPage(),
      },
      {
        id: 1,
        title: 'Search Vendors',
        url: relationshipSearchVendorsPage(),
      },
    ],
  },
  {
    id: 2,
    title: 'Recruitment Questionnaires',
    icon: BusinessCenterOutlined,
    items: [
      {
        id: 1,
        title: 'Manage Questionnaires',
        url: recruitmentQuestionnairesManagePage(),
      },
    ],
  },
  {
    id: 4,
    title: 'Profile',
    url: PROFILE,
    icon: Diversity3Rounded,
  },
  {
    id: 5,
    title: 'Notifications',
    url: REPORTS,
    icon: Diversity3Rounded,
  },
  {
    id: 8,
    title: 'Sign Out',
    url: SIGNOUT,
    icon: LogoutOutlined,
  },
];

export const tableHeaderStyles = {
  fontSize: 'sm',
  fontFamily: 'Raleway',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  fontWeight: 'medium',
  flex: 1,
};

export const tableTextStyles = {
  fontFamily: 'Poppins',
  fontSize: 'xs',
  lineHeight: 1,
  fontWeight: 'normal',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flex: 1,
  my: 2,
};
